import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'
import styled from 'styled-components'

const LoginFooterRow = styled(Row)`
  margin-top: auto;
  display: flex;
  padding: ${props => props.padding || '20px 80px'};
  padding: 0 2%;
  @media (min-width: 1280px){
    padding: 2% 10%; 
  }
  @media (min-width: 1400px){
    padding: 3% 15%; 
  }
`

const CopyrightContent = styled.div`
  font-size: 14px;
  color: #8a8a8a;
  opacity: 0.5;
  text-align: center;
  @media (min-width: 1600px){
    font-size: 16px;
  }
`

export default function LoginFooter ({ space }) {
  return (
    <LoginFooterRow
      type="flex"
      justify="center"
      padding={space?.padding}
    >
      <CopyrightContent>
        <p style={{ marginBottom: 0 }}>中華民國交通部 版權所有</p>
        Copyright © 2022 MOTC All Rights Reserved.
      </CopyrightContent>
    </LoginFooterRow>
  )
}

LoginFooter.propTypes = {
  space: PropTypes.object
}
