import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'

import HomeMenu from 'component/HomeMenu'
import { ReactComponent as SmallLogo } from 'images/Logo_Umaji_s.svg'

const { Header } = Layout

const Container = styled.div`
  background: #f7f7f7;
`
const HomeHeaders = styled(Header)`
  background: #f7f7f7 !important;
  margin: 3.5% 10% !important;
  padding: 0 !important;
`

const Headers = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 80px;
  line-height: 40px; // vertical center
  background-color: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 14px 40px !important;
  @media (min-width: 1600px){
    padding: 14px 60px !important;
  }
`
const SmallLogos = styled(SmallLogo)`
  vertical-align: middle;
`

const ColAutoContent = styled(Col)`
  display: flex;
  justify-content: end;
`

export default function AppHeader () {
  const location = useLocation()
  const pathname = location.pathname

  let haveHeader = false
  if (
    pathname === '/' ||
    pathname === '/documents' ||
    pathname === '/overview' ||
    pathname === '/account' ||
    pathname === '/scenario'
  ) {
    haveHeader = true
  }

  return (
    <>
    {haveHeader && pathname === '/' &&
      <Container className='main-font'>
        <HomeHeaders>
          <Row justify="end">
            <HomeMenu />
          </Row>
        </HomeHeaders>
      </Container>
    }
    {haveHeader && pathname !== '/' &&
      <Headers className='main-font'>
        <Row wrap={false} align="middle">
          <Col flex="none">
            <div>
              <Link to="/">
                <SmallLogos alt="logo" />
              </Link>
            </div>
          </Col>
          <ColAutoContent flex="auto">
            <HomeMenu />
          </ColAutoContent>
        </Row>
      </Headers>
    }
    { !haveHeader && <></> }
    </>
  )
}
