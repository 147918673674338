import { configureStore } from '@reduxjs/toolkit'
import loginReducer from 'reduxSlice/loginSlice'
import maintainReducer from 'reduxSlice/maintainSlice'

export default configureStore({
  reducer: {
    login: loginReducer,
    maintain: maintainReducer
  }
})
