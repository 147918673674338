import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import { Layout, Row, notification } from 'antd'
import Icon from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'

import { apiResetPassword } from 'API'
import LoginFooter from 'component/LoginFooter'
import LoginLogo from './LoginLogo'
import { Form } from 'component/formStyled'
import { RoundInputPassword, MediaButton, RoundButton } from 'component/common'
import { LoginSider, LoginTitle, LoginContent, ColForLogin, RowForButtons } from './commonStyled'
import forgetIllustration from 'images/forgetpw-illustration.png'
import { ReactComponent as icPassword } from 'images/ic_password.svg'

export default function ResetPassword () {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const history = useHistory()
  const code = qs.parse(location.search, { ignoreQueryPrefix: true }).code

  const [user, setUser] = useState({
    password: '',
    confirm: ''
  })

  const handleValuesChange = (changeItem) => {
    setUser({ ...user, ...changeItem })
  }

  const notificationkey = 'reset'
  const handleToLogin = (second) => {
    notification.close(notificationkey)
    setTimeout(() => {
      history.push('/login')
    }, 800)
  }
  const NotificationButton = (
    <>
      <RoundButton
        type="primary"
        onClick={handleToLogin}
        style={{ width: 140, fontWeight: 600 }}
      >
        {formatMessage({ id: 'reset.loginPage' })}
      </RoundButton>
    </>
  )

  const onFinish = () => {
    const data = {
      password: user.password,
      verification_code: code
    }
    apiResetPassword(data)
      .then(response => {
        notification.open({
          message: formatMessage({ id: 'reset.updatedAlertTitle' }),
          description: formatMessage({ id: 'reset.updatedAlertDescription' }),
          duration: 2
        })
        setTimeout(() => {
          history.push('/login')
        }, 2500)
      })
      .catch((error) => {
        const errorMsg = error.response.data.error
        console.log('API Error: ', errorMsg.code, errorMsg.msg)
        if (errorMsg.code === 10000) {
          notification.open({
            message: formatMessage({ id: 'reset.ErrorTitle' }),
            description: formatMessage({ id: 'reset.repeatErrorDescription' }),
            duration: null,
            key: notificationkey,
            btn: NotificationButton
          })
        } else {
          notification.open({
            message: formatMessage({ id: 'reset.ErrorTitle' }),
            description: formatMessage({ id: 'reset.commonErrorDescription' })
          })
        }
      })
  }
  return (
    <Layout className='main-font'>
      <LoginSider>
        <Row type="flex" justify="center" align="middle">
          <ColForLogin>
            <LoginLogo />
            <LoginTitle>
              <FormattedMessage id="reset.resetPassword" />
            </LoginTitle>
            <Form
              layout="vertical"
              onFinish={onFinish}
              onValuesChange={handleValuesChange}
            >
              <Form.Item
                label={formatMessage({ id: 'reset.newPassword' })}
                name="password"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'reset.enternewPassword' })
                  },
                  {
                    pattern: /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    message: formatMessage({ id: 'reset.PattenError' })
                  }
                ]}
                required={false}
              >
                <RoundInputPassword
                  prefix={<Icon component={icPassword} />}
                  placeholder={formatMessage({ id: 'reset.enternewPassword' })}
                />
              </Form.Item>
              <Form.Item
                label={formatMessage({ id: 'reset.confirmNewPassword' })}
                name="confirm"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'reset.repeatNewPassword' })
                  },
                  {
                    pattern: /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    message: formatMessage({ id: 'reset.PattenError' })
                  },
                  ({ getFieldValue }) => ({
                    validator (_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }

                      return Promise.reject(new Error(formatMessage({ id: 'reset.PasswordNoMatch' })))
                    }
                  })
                ]}
                required={false}
              >
                <RoundInputPassword
                  prefix={<Icon component={icPassword} />}
                  placeholder={formatMessage({ id: 'reset.repeatNewPassword' })}
                />
              </Form.Item>
              <RowForButtons
                type="flex"
                justify="end"
              >
                <MediaButton
                  key="submit"
                  type="primary"
                  style={{ width: 150 }}
                  htmlType="submit"
                >
                  <FormattedMessage id="reset.submit" />
                </MediaButton>
              </RowForButtons>
            </Form>
          </ColForLogin>
        </Row>
        <LoginFooter/>
      </LoginSider>
      <LoginContent>
        <img
          src={forgetIllustration}
          className="illustration"
          alt="forget-password-illustration"
        />
      </LoginContent>
    </Layout>
  )
}
