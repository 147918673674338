import { Layout, Typography, Row, Col } from 'antd'
import styled from 'styled-components'

import { RoundInput } from 'component/common'

const { Sider, Content } = Layout
const { Title } = Typography

export const LoginSider = styled(Sider)`
  flex: 0 0 33% !important;
  max-width: 33% !important;
  min-width: 33% !important;
  width: 33% !important;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
  height: 100vh;
  overflow: auto;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`
export const ColForLogin = styled(Col)`
  width: 100%;
  padding: 0 3%; 
  @media (min-width: 1280px){
    padding: 10%; 
  }
  @media (min-width: 1400px){
    padding: 15%; 
  }
`

export const LogoContent = styled.div`
  display: flex;
  justify-content: center;
`
export const LoginTitle = styled(Title)`
  font-size: 28px !important;
  margin-bottom: 0.7em !important;
  line-height: 1.3 !important;
  letter-spacing: 1.1px;
  color: #333333 !important;
  @media (min-width: 1480px){
    margin-bottom: 1em !important;
    font-size: 40px !important;
  }
`

export const LoginContent = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  min-height: 100vh;
  padding: 20px;
  .illustration {
    width: 600px;
  }
`

export const DisableInput = styled(RoundInput)`
  .ant-input[disabled] {
    color: #333333;
    background-color: #f0f3f5;
  }
`
export const RowForButtons = styled(Row)`
  margin-top: 20px;
  @media (min-width: 1280px){
    margin-top: 68px;
  }
`

export const Description = styled.p`
  @media (min-width: 1600px){
    font-size: 16px;
  }
  @media (min-width: 1920px){
    font-size: 18px; 
  }
`
