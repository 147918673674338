import React from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'

import OverviewDiagram from 'images/Overview_diagram.png'

const { Title, Paragraph } = Typography

const Contents = styled.div`
  padding: 24px;
  background-color: #fff;
`
const Paragraphs = styled(Paragraph)`
  font-size: 16px;
  color: #8a8a8a;
  ul {
    list-style-type: disc;
  }
`
const H2 = styled(Title)`
  font-size: 18px !important;
  font-weight: 900 !important;
  letter-spacing: 0.45px;
  margin-bottom: 20px !important;
  text-align: center;
`

const ImageContent = styled.div`
  margin-top: 60px;
  .overview-img {
    max-width: 100%;
    height: auto;
  } 
`
export default function MobileOverview () {
  return (
    <Contents className='main-font'>
      <H2 level={2}>預約共乘接送平台功能模組</H2>
      <Paragraphs>
        預約共乘接送平台功能模組提供便利與創新的交通服務，經由專業的「預約共乘媒合與行程指派」API串接，可讓開發人員輕鬆建立應用程式。
      </Paragraphs>
      <Paragraphs>
        <p>主要功能為：</p>
        <ul>
          <li>預約叫車媒合</li>
          <li>行程指派</li>
          <li>車輛路線優化</li>
          <li>多人共乘服務</li>
        </ul>
      </Paragraphs>
      <Paragraphs>
        提供乘客智慧化共乘預約服務，大大提升車隊派遣效率、降低營運成本。同時擁有與其他平台協同工作的彈性發展，以增加不同場域的服務涵蓋率，擴增業務之版圖。
      </Paragraphs>
      <Paragraphs>
        <p>適合以下業者API串接：</p>
        <ul>
          <li>計程車隊與多元計程車隊</li>
          <li>出租車業者</li>
          <li>復康巴士業者</li>
          <li>經營學生共乘業者</li>
          <li>經營員工共乘業者</li>
        </ul>
      </Paragraphs>
      <ImageContent>
        <img src={OverviewDiagram} className="overview-img" />
      </ImageContent>
    </Contents>
  )
}
