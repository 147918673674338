import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Typography } from 'antd'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import maintenanceImg from 'images/maintenance.png'
import Copyright from 'component/Copyright'

const { Title } = Typography

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
`
export const ImageContent = styled.div`
  margin: 0px 0px 28px 0;
  img {
    max-width: 100%;
    height: auto;
  }
`
export const H1Title = styled(Title)`
  text-align: center;
  font-weight: 900 !important;
  font-size: 24px !important;
`
export const Info = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 1.78;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
`

export default function MobileMaintainPage () {
  const maintainTime = useSelector(state => state.maintain.data)

  moment.locale('en')
  const formatStyle = 'YYYY/MM/DD hh:mm A'
  const startTime = moment.unix(maintainTime.start_time).format(formatStyle)
  const endTime = moment.unix(maintainTime.end_time).format(formatStyle)

  return (
    <Contents>
      <Row justify="center" style={{ margin: '80px 24px 15px 24px' }}>
        <Col>
          <ImageContent>
            <img src={maintenanceImg} className="maintenance" alt="maintenance" />
          </ImageContent>
          <H1Title>
            <FormattedMessage id="maintain.TemporarilyUnavailable" />
          </H1Title>
          <Info>
            <FormattedMessage id="maintain.isMaintaining" /><br />
            <FormattedMessage
              id="maintain.isMaintainingFrom"
              values={{ startTime: startTime, endTime: endTime }}
            />
            <br />
            <FormattedMessage id="maintain.isMaintainingApologize" />
          </Info>
        </Col>
      </Row>
      <Footer>
        <Copyright />
      </Footer>
    </Contents>
  )
}
