import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import { Layout, Row, notification, Button, message } from 'antd'
import Icon from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'

import { apiForgetPassword } from 'API'
import { MediaButton, DisableButton } from 'component/common'
import LoginFooter from 'component/LoginFooter'
import LoginLogo from './LoginLogo'
import { Form } from 'component/formStyled'
import { LoginSider, LoginTitle, LoginContent, DisableInput, ColForLogin, RowForButtons, Description } from './commonStyled'
import forgetIllustration from 'images/forgetpw-illustration.png'
import { ReactComponent as icEmail } from 'images/ic_email.svg'
import { ReactComponent as icConfirmed } from 'images/ic_confirmed.svg'

export default function ForgetPassword () {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const [email, setEmail] = useState('')
  const [isSend, setIsSend] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  // const currentLanguage = useSelector((state) => state.language.currentLocale)

  const handleValuesChange = (changeItem) => {
    setEmail(changeItem.email)
  }

  const onFinish = () => {
    setIsSubmit(true)
    const data = {
      email,
      language: 'zh',
      redirect_url: `${window.location.origin}/reset-password`
    }
    message.loading(formatMessage({ id: 'common.Processing' }))
    apiForgetPassword(data).then(response => {
      message.destroy()
      setIsSend(true)
      notification.open({
        message: formatMessage({ id: 'forget.resetAlertTitle' }),
        description: formatMessage({ id: 'forget.resetAlertDescription' }),
        duration: null
      })
    }).catch((error) => {
      if (error.response) {
        const errorMsg = error.response.data.error
        console.log('errorMsg', errorMsg)
        if (errorMsg.code === 40002) {
          form.setFields([
            {
              name: 'email',
              errors: [formatMessage({ id: 'forget.noAccount' })]
            }
          ])
        }
        message.destroy()
        setIsSubmit(false)
      }
    })
  }

  return (
    <Layout className='main-font'>
      <LoginSider>
        <Row type="flex" justify="center" align="middle">
          <ColForLogin>
            <LoginLogo />
            <LoginTitle>
              <FormattedMessage id="forget.forgotPassword" />
            </LoginTitle>
            <Description>
              {!isSend &&
                <FormattedMessage id="forget.resetInstructions" />
              }
              {isSend &&
                <FormattedMessage id="forget.sendInstructions" />
              }
            </Description>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onValuesChange={handleValuesChange}
              initialValues={{
                email: ''
              }}
            >
              <Form.Item
                label={formatMessage({ id: 'forget.email' })}
                name="email"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'forget.enterEmail' })
                  },
                  {
                    type: 'email',
                    message: formatMessage({ id: 'forget.validEmail' })
                  }
                ]}
                required={false}
              >
                <DisableInput
                  prefix={<Icon component={icEmail} />}
                  disabled={isSend}
                  autoComplete="off"
                  placeholder={formatMessage({ id: 'forget.enterEmail' })}
                />
              </Form.Item>
              <RowForButtons type="flex" justify="end">
                {!isSend && (
                  <DisableButton>
                    <Button
                      disabled={isSubmit}
                      style={{ minWidth: 130, marginLeft: 16, fontWeight: 600 }}
                      htmlType="submit"
                      type="primary"
                    >
                      <FormattedMessage id="forget.send" />
                    </Button>
                  </DisableButton>
                )}
                {isSend && (
                  <MediaButton
                    icon={
                      <Icon component={icConfirmed} style={{ fontSize: 16 }} />
                    }
                    style={{
                      minWidth: 130,
                      marginLeft: 16,
                      backgroundColor: '#5bcc52',
                      color: '#ffffff',
                      cursor: 'not-allowed'
                    }}
                  >
                    <FormattedMessage id="forget.resetSent" />
                  </MediaButton>
                )}
              </RowForButtons>
            </Form>
          </ColForLogin>
        </Row>
        <LoginFooter/>
      </LoginSider>
      <LoginContent>
        <img
          src={forgetIllustration}
          className="illustration"
          alt="forget-password-illustration"
        />
      </LoginContent>
    </Layout>
  )
}
