import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  status: ''
}

const maintainSlice = createSlice({
  name: 'maintain',
  initialState,
  reducers: {
    maintainUpdate (state, action) {
      state.status = action.payload.status
      state.data = action.payload.maintainTime
    },
    setDefault (state, action) {
      state.status = ''
      state.data = {}
    }
  }
})

export const { maintainUpdate, setDefault } = maintainSlice.actions

export default maintainSlice.reducer
