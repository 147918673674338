import { Row, Typography } from 'antd'
import styled from 'styled-components'

import { RoundInput, MediaButton } from 'component/common'

const { Title } = Typography

export const Container = styled.div`
  margin: 60px 24px 0 24px;
`

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
`

export const LoginTitle = styled(Title)`
  font-size: 28px !important;
  font-weight: 900 !important;
  letter-spacing: 1.1px;
  color: #333333 !important;
`
export const Description = styled.p`
  font-size: 16px;
  margin: 24px 0 40px 0;
`
export const DisableInput = styled(RoundInput)`
  .ant-input[disabled] {
    color: #333333;
    background-color: #f0f3f5;
  }
`
export const RowForButtons = styled(Row)`
  margin-top: 68px;
`
export const MediaButtons = styled(MediaButton)`
  width: 240px;
  padding: 12px;
  font-size: 20px;
  height: 52px;
`
