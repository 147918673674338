import React from 'react'
import { useIntl } from 'react-intl'

import { Form } from 'component/formStyled'
import { RoundInput } from 'component/common'

export const FormLastName = () => {
  const intl = useIntl()
  return (
    <Form.Item
      label={intl.formatMessage({ id: 'lastName' })}
      name="last_name"
      rules={[
        {
          required: true,
          message: intl.formatMessage({ id: 'lastName.enter' })
        },
        {
          pattern: /\p{sc=Han}{1,45}$|^[A-Za-z\s]{1,45}$/u,
          message: intl.formatMessage({ id: 'lastName.valid' })
        }
      ]}
    >
      <RoundInput autoComplete="off" placeholder={intl.formatMessage({ id: 'lastName' })} />
    </Form.Item>
  )
}

export const FormNoRequiredLastName = () => {
  const intl = useIntl()
  return (
    <Form.Item
      label={intl.formatMessage({ id: 'lastName' })}
      name="last_name"
      rules={[
        {
          pattern: /\p{sc=Han}{1,45}$|^[A-Za-z\s]{1,45}$/u,
          message: intl.formatMessage({ id: 'lastName.valid' })
        }
      ]}
    >
      <RoundInput autoComplete="off" placeholder={intl.formatMessage({ id: 'lastName' })} />
    </Form.Item>
  )
}

export const FormFirstName = () => {
  const intl = useIntl()
  return (
    <Form.Item
      label={intl.formatMessage({ id: 'firstName' })}
      name="first_name"
      rules={[
        {
          required: true,
          message: intl.formatMessage({ id: 'firstName.enter' })
        },
        {
          pattern: /\p{sc=Han}{1,45}$|^[A-Za-z\s]{1,45}$/u,
          message: intl.formatMessage({ id: 'firstName.valid' })
        }
      ]}
    >
      <RoundInput autoComplete="off" placeholder={intl.formatMessage({ id: 'firstName' })} />
    </Form.Item>
  )
}

export const FormNoRequiredFirstName = () => {
  const intl = useIntl()
  return (
    <Form.Item
      label={intl.formatMessage({ id: 'firstName' })}
      name="first_name"
      rules={[
        {
          pattern: /\p{sc=Han}{1,45}$|^[A-Za-z\s]{1,45}$/u,
          message: intl.formatMessage({ id: 'firstName.valid' })
        }
      ]}
    >
      <RoundInput autoComplete="off" placeholder={intl.formatMessage({ id: 'firstName' })} />
    </Form.Item>
  )
}

export const FormFullName = () => {
  const intl = useIntl()
  return (
    <Form.Item
      label={intl.formatMessage({ id: 'order.FullName' })}
      name="name"
      rules={[
        {
          required: true,
          message: intl.formatMessage({ id: 'order.EnterBuyerName' })
        },
        {
          pattern: /\p{sc=Han}{2,45}$|^[A-Za-z\s]{2,45}$/u,
          message: intl.formatMessage({ id: 'order.ValidBuyerName' })
        }
      ]}
    >
      <RoundInput autoComplete="off" placeholder={intl.formatMessage({ id: 'order.FullNamePlaceholder' })} />
    </Form.Item>
  )
}
