import React from 'react'
import { useIntl } from 'react-intl'

import { Form } from 'component/formStyled'
import { RoundInput } from 'component/common'

export const FormEmail = () => {
  const intl = useIntl()
  return (
    <Form.Item
      label={intl.formatMessage({ id: 'Email' })}
      name="email"
      rules={[
        {
          required: true,
          message: intl.formatMessage({ id: 'email.enterEmail' })
        },
        {
          type: 'email',
          message: intl.formatMessage({ id: 'email.validEmail' })
        }
      ]}
    >
      <RoundInput
        autoComplete="off"
        placeholder={intl.formatMessage({ id: 'Email' })}
      />
    </Form.Item>
  )
}

export const FormNoRequiredEmail = () => {
  const intl = useIntl()
  return (
    <Form.Item
      label={intl.formatMessage({ id: 'Email' })}
      name="email"
      rules={[
        {
          type: 'email',
          message: intl.formatMessage({ id: 'email.validEmail' })
        }
      ]}
    >
      <RoundInput
        autoComplete="off"
        placeholder={intl.formatMessage({ id: 'Email' })}
      />
    </Form.Item>
  )
}

export const FormOrderEmail = () => {
  const intl = useIntl()
  return (
    <Form.Item
      label={intl.formatMessage({ id: 'Email' })}
      name="email"
      rules={[
        {
          required: true,
          message: intl.formatMessage({ id: 'email.enterEmail' })
        },
        {
          type: 'email',
          message: intl.formatMessage({ id: 'email.validEmail' })
        }
      ]}
    >
      <RoundInput
        autoComplete="off"
        placeholder={intl.formatMessage({ id: 'order.EmailPlaceholder' })}
      />
    </Form.Item>
  )
}
