import React from 'react'
import { useSelector } from 'react-redux'
import { Alert } from 'antd'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

export const ExpectContent = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  .ant-alert {
    padding: 8px 10px;
  }
  .ant-alert-warning {
    border: none;
  }
  @media (max-width: 768px){
    top: 66px;
  }
`

export default function MaintainAlert () {
  const maintainTime = useSelector(state => state.maintain.data)

  moment.locale('en')
  const formatStyle = 'YYYY/MM/DD hh:mm A'
  const startTime = moment.unix(maintainTime.start_time).format(formatStyle)
  const endTime = moment.unix(maintainTime.end_time).format(formatStyle)

  const message = <FormattedMessage
    id="maintain.expectMaintain"
    values={{ startTime: startTime, endTime: endTime }}
  />

  return (
    <ExpectContent>
      <Alert type="warning" message={message} closable />
    </ExpectContent>
  )
}
