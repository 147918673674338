import axios from 'axios'
import Cookies from 'js-cookie'

import { entryPoint } from 'config/index.js'

const baseRequest = axios.create({
  baseURL: `${entryPoint}/v2`,
  headers: {
    'content-type': 'application/json'
  }
})

baseRequest.interceptors.request.use(config => {
  const token = Cookies.get('metTk')
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  return config
}, error => {
  return error
})

const shareTripRequest = axios.create({
  baseURL: `${entryPoint}/v2`,
  headers: {
    'content-type': 'application/json'
  }
})
shareTripRequest.interceptors.request.use(config => {
  const token = Cookies.get('riderTk')
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  return config
}, error => {
  return error
})

// login api
export const apiLogin = (data) => baseRequest.post('/vendor/users/session', data)
export const apiLogot = (id) => baseRequest.delete(`/vendor/users/session/${id}`)
export const apiForgetPassword = (data) => baseRequest.post('/vendor/users/verification', data)
export const apiResetPassword = (data) => baseRequest.patch('/vendor/users', data)

// authentication api
// export const apiGetUsers = (params) => baseRequest.get('/admin/users', params)
export const apiGetUser = (id) => baseRequest.get(`/vendor/users/${id}`)
export const apiRegistration = (data) => baseRequest.post('/vendor/users/', data)
export const apiUpdateCallback = (vendorId, data) => baseRequest.patch(`/vendor/vendors/${vendorId}`, data)
// export const apiUpdateUser = (data) => baseRequest.patch(`/admin/users/${data.id}`, data)
// export const apiDeleteUser = (id) => baseRequest.delete(`/admin/users/${id}`)
