class StringUtils {
  static formatName = (firstName, lastName) => {
    if (!/[^A-Za-z0-9]/g.exec(firstName) && !/[^A-Za-z0-9]/g.exec(lastName)) {
      return `${firstName} ${lastName}`
    } else return `${lastName}${firstName}`
  };

  static getDisplayName (user) {
    if (
      !/[^A-Za-z0-9]/g.exec(user.first_name) &&
      !/[^A-Za-z0-9]/g.exec(user.last_name)
    ) {
      return `${user.first_name} ${user.last_name}`
    } else return `${user.last_name}${user.first_name}`
  }

  static getDriverDisplayName (user) {
    if (
      !/[^A-Za-z0-9]/g.exec(user.driver_first_name) &&
      !/[^A-Za-z0-9]/g.exec(user.driver_last_name)
    ) {
      return `${user.driver_first_name} ${user.driver_last_name}`
    } else return `${user.driver_last_name}${user.driver_first_name}`
  }
}

export default StringUtils
