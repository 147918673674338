import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { RoundInput } from 'component/common'
import { Form } from 'component/formStyled'

/**
 * name: Form.Item name
 * labe: Form.Item label
 * placeholder: placeholder
 * requiredText: Form.Item required text
 * isRequired: default is true
 * rulesData: Form.Item rules Data
 */
const FormInput = (params) => {
  const { formatMessage } = useIntl()
  const {
    label,
    name,
    placeholder,
    rulesData,
    isRequired = true,
    requiredText
  } = params

  let rules = []
  if (rulesData) {
    rules = rulesData
  } else if (isRequired) {
    rules = [
      {
        required: true,
        message: formatMessage({ id: requiredText })
      }
    ]
  }

  return (
    <Form.Item
      label={formatMessage({ id: label })}
      name={name}
      rules={rules}
    >
      <RoundInput
        autoComplete="off"
        placeholder={formatMessage({ id: placeholder })}
      />
    </Form.Item>
  )
}

FormInput.propTypes = {
  name: PropTypes.string,
  drivers: PropTypes.array
}

export default FormInput
