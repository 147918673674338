import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import Icon from '@ant-design/icons'

import { apiLogot } from 'API'
import { loginUpdated } from 'reduxSlice/loginSlice'
import { IconButton } from 'component/common'
import StringUtils from 'utils/StringUtils'
import { ReactComponent as icSettings } from 'images/ic_settings.svg'
import { ReactComponent as IconLogout } from 'images/ic_logout.svg'
import { ReactComponent as IconPerson } from 'images/ic_person.svg'

const MenuStyled = styled(Menu)`
  font-size: 18px;
  font-weight: bold;
  line-height: normal !important;
  background: transparent;
  border-bottom: none !important;
  @media (min-width: 1920px){ 
    font-size: 20px; 
  }
  .ant-menu-item {
    height: 52px;
    text-align: center;
    padding: 12px !important;
  }
  .ant-menu-item::after {
    border-bottom: none !important;
  }
  .ant-menu-item:hover::after {
    border-bottom: none !important;
  }
  .ant-menu-item-selected a{
    color: #1596b2 !important;
  }
`
const ItemPrimary = styled(Menu.Item)`
  width: 160px;
  margin-left: 24px;
  @media (min-width: 1600px){
    width: 180px;
  }
  @media (min-width: 1920px){
    width: 200px;
  }
`
const ItemSecondary = styled(Menu.Item)`
  width: 120px;
  border-radius: 16px;
  border: solid 1px #1596b2;
  margin-left: 60px;
  @media (min-width: 1600px){
    width: 140px;
  }
  @media (min-width: 1920px){
    width: 160px;
  }
`
const LinkPrimary = styled(Link)`
  color: #8a8a8a !important;
`
const SettingContent = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  margin-left: 60px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #8a8a8a;
  @media (min-width: 1920px){ 
    font-size: 20px; 
  }
  .name {
    margin-right: 15px;
  }
  .icon-setting {
    font-size: 28px;
  }
`
const SetupDropdown = styled(Dropdown)`
  font-size: 18px
`

const SetupMenu = styled(Menu)`
  width: 200px;
  top: 17px;
  padding: 0;
  color: #333;
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 16px 12px;
    font-size: 18px !important;
  }
  .ant-dropdown-menu-item-icon {
    vertical-align: middle;
  }
  .ant-dropdown-menu-item > span,
  .ant-dropdown-menu-submenu-title > span {
    vertical-align: middle;
  }
  .ant-dropdown-menu-submenu-title > svg {
    vertical-align: middle;
  }
`

export default function HomeMenu () {
  const userId = Cookies.get('userId')
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const isLogin = useSelector((state) => state.login.isLogin)
  const user = useSelector((state) => state.login.user)

  let name
  if (user?.first_name && user?.last_name) {
    name = StringUtils.getDisplayName(user)
  }

  const logout = () => {
    apiLogot(userId).then(response => {
      Cookies.remove('metTk')
      Cookies.remove('userId')
      dispatch(loginUpdated(false))
      history.push('/')
    })
  }

  const menu = (
    <SetupMenu>
      <Menu.Item
        key="1"
        icon={<IconPerson />}
        onClick={() => history.push('/account')}
      >
        <FormattedMessage id="api.menuAccount" />
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<IconLogout />}
        onClick={logout}
      >
        <FormattedMessage id="common.logout" />
      </Menu.Item>
    </SetupMenu>
  )

  return (
    <>
    <MenuStyled
      selectedKeys={[location.pathname]}
      mode="horizontal"
      disabledOverflow={true}
    >
      <ItemPrimary key="/overview">
        <LinkPrimary to="/overview">
          <FormattedMessage id="api.Overview" />
        </LinkPrimary>
      </ItemPrimary>
      <ItemPrimary key="/documents">
        <LinkPrimary to="/documents">
          <FormattedMessage id="api.Documents" />
        </LinkPrimary>
      </ItemPrimary>
      { !isLogin && (
        <ItemSecondary key="/login">
          <Link to="/login" style={{ color: '#1596b2' }}>
            <FormattedMessage id="login.signIn" />
          </Link>
        </ItemSecondary>
      )}
    </MenuStyled>
    { isLogin && (
      <SettingContent>
        <span className='name'>{name}</span>
        <SetupDropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
        >
          <IconButton
            type="text"
            onClick={(e) => e.preventDefault()}
            icon={<Icon component={icSettings} className="icon-setting" />}
          />
        </SetupDropdown>
      </SettingContent>
    )}
    {/* {showAccount && (
      <AccountDetailModal
        user={user}
        name={name}
        visible={showAccount}
        closeModal={closeModal}
      />
    )} */}
    </>
  )
}
