import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

import styled from 'styled-components'

import { documentUrl } from 'config/index.js'

const Contents = styled.div`
  h2, h3 {
    font-weight: 900 !important;
  }
  .swagger-ui .opblock-summary-control:focus {
    outline: none;
  }
`
export default function DocumentsSwagger () {
  return (
    <Contents>
      <SwaggerUI
        url={documentUrl}
        deepLinking={true}
      />
    </Contents>
  )
}
