import styled from 'styled-components'
import {
  Button,
  DatePicker,
  Image,
  Input,
  InputNumber,
  Layout,
  Modal,
  Select,
  Table,
  Tag,
  Divider
} from 'antd'

const RoundInput = styled(Input)`
  border-radius: 8px;
  border-color: #bdbdbd;
  font-size: 14px;
  height: 40px;
  @media (min-width: 1600px){
    font-size: 16px; 
  }
  @media (min-width: 1920px){
    font-size: 18px; 
  }
`
const PrefixInput = styled(Input)`
  .ant-input-group-addon {
    font-size: 14px;
    border-radius: 8px 0 0 8px;
    height: 40px;
  }
  .ant-input {
    border-radius: 0 8px 8px 0;
    border-color: #bdbdbd;
    font-size: 14px;
    height: 40px;
  }
  @media (min-width: 1600px){
    .ant-input-group-addon, .ant-input {
      font-size: 16px; 
    }
  }
  @media (min-width: 1920px){
    .ant-input-group-addon, .ant-input {
      font-size: 18px; 
    }
  }
`
const FilterInput = styled(RoundInput)`
width: 150px; 
height: 40px;
@media (min-width: 1600px){
  width: 165px; 
}
@media (min-width: 1920px){
  width: 180px; 
}
`

const RoundInputNumber = styled(InputNumber)`
  border-radius: 8px;
  border-color: #bdbdbd;
  font-size: 14px;
  @media (min-width: 1600px){
    font-size: 16px; 
  }
  @media (min-width: 1920px){
    font-size: 18px; 
  }
`
const RoundInputPassword = styled(Input.Password)`
  border-radius: 8px;
  border-color: #bdbdbd;
  font-size: 18px;
`

const RoundTextArea = styled(Input.TextArea)`
  border-radius: 8px;
  border-color: #bdbdbd;
  font-size: 14px;
  @media (min-width: 1600px){
    font-size: 16px; 
  }
  @media (min-width: 1920px){
    font-size: 18px; 
  }
`

const RoundButton = styled(Button)`
  border-radius: 8px;
  min-width: 40px;
  min-height: 40px;
  box-shadow: 0 2px 5px LightGray;
`

const AddButton = styled(RoundButton)`
  font-weight: 900 !important;
  font-size: 14px;
  @media (min-width: 1600px){
    font-size: 16px;
  }
  @media (min-width: 1920px){
    font-size: 18px; 
  }
`
export const MediaButton = AddButton

const OutlineButton = styled(Button)`
  border-radius: 8px;
  min-width: 40px;
  min-height: 40px;
  font-size: 16px;
  color: #0099e6;
  padding: 4px 20px 4px 10px;
  border: solid 2px #0099e6;
`
const OrderButton = styled(MediaButton)`
  width: 160px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
`
const DisableButton = styled.div`
  .ant-btn {
    border-radius: 8px;
    min-height: 40px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    color: #ffffff;
    background: #0099e6;
    opacity: 1;
    font-size: 14px;
  }
  @media (min-width: 1600px){
    .ant-btn {
      font-size: 16px;
    }
  }
  @media (min-width: 1920px){
    .ant-btn {
      font-size: 18px;
    }
  }
  .ant-btn[disabled] {
    opacity: 0.5;
  }
  .ant-btn[disabled], .ant-btn[disabled]:hover {
    background: #0099e6;
    color: #ffffff;
    opacity: 0.5;
  }
`

const IconButton = styled(Button)`
  border-radius: 8px;
`

const RoundTag = styled(Tag)`
  border-radius: 10px;
  padding: 0 7px;
  font-weight: bold;
  @media (min-width: 1600px){
    padding: 0 10px;
  }
  @media (min-width: 1920px){
    padding: 0 16px;
  }
`

const RoundSelect = styled(Select)`
  .ant-select {
    font-size: 14px; 
  }
  .ant-select-selector {
    height: 40px !important;
    border-radius: 8px !important;
    text-align: start;
    font-size: 14px;
  }
  @media (min-width: 1600px){
    .ant-select, .ant-select-selector,ant-select-item-option-content {
      font-size: 16px; 
    }
  }
  @media (min-width: 1920px){
    .ant-select, .ant-select-selector,ant-select-item-option-content {
      font-size: 18px; 
    }
  }

  .ant-select-selection-item {
    line-height: 40px !important;
  }
  .ant-select-selection-placeholder {
    padding: 3px !important;
  }
`
const RoundMultipleSelect = styled(Select)`
  .ant-select-selector {
    min-height: 40px !important;
    border-radius: 8px !important;
    text-align: start;
    font-size: 14px;
  }
  @media (min-width: 1600px){
    .ant-select, .ant-select-selector,ant-select-item-option-content {
      font-size: 16px; 
    }
  }
  @media (min-width: 1920px){
    .ant-select, .ant-select-selector,ant-select-item-option-content {
      font-size: 18px; 
    }
  }
  .ant-select-selection-placeholder {
    padding: 3px !important;
  }
`

const DatePickers = styled(DatePicker)`
  border-radius: 8px;
  border-color: #bdbdbd;

  input {
    font-size: 14px;
  }
  @media (min-width: 1600px){
    input {
      font-size: 16px;
    }
  }
  @media (min-width: 1920px){
    input {
      font-size: 18px;
    }
  }
`
const RoundDatePicker = styled(DatePickers)`
  width: 130px; 
  height: 40px;
  @media (min-width: 1600px){
    width: 140px; 
  }
  @media (min-width: 1920px){
    width: 160px; 
  }
`
const { RangePicker } = RoundDatePicker

const RoundRangePicker = styled(RangePicker)`
  border-radius: 8px;
  border-color: #bdbdbd;
  width: 100%;
  height: 40px;
  @media (min-width: 1600px){
    input {
      font-size: 16px;
    }
  }
  @media (min-width: 1920px){
    input {
      font-size: 18px;
    }
  }
`

const RoundTable = styled(Table)`
  .ant-table {
    margin: 0 28px;
    font-size: 14px;
  }
  @media (min-width: 1600px){
    .ant-table {
      font-size: 16px;
    }  
  }
  @media (min-width: 1920px){
    .ant-table {
      font-size: 18px;
    }  
  }

  // hide thead th dash 
  .ant-table-thead th.ant-table-cell::before {
    height: 0px !important;
  }

  .ant-table-title {
    padding: 0;
  }
  
  .ant-table-cell {
    background: none;
    height: 60px !important;
    padding: 0 10px;
  }
  
  th {
    color: #8a8a8a !important;
    border: none !important;
    padding: 0 10px;
  }

  th:not(:first-child) > div {
    padding: 0 10px;
  }
  .ant-table-thead .status {
    font-weight: bold;
  }
  
  .ant-table-column-sorters,
  .ant-table-column-has-sorters {
    padding: 0;  
    font-weight: bold;
  }

  .ant-spin-container > .ant-pagination {
    display: none !important;
  }

  .ant-table-row .whiteSpace {
    white-space: normal !important;
  }

  .ant-table-row .actions {
    transition: opacity 200ms;
    opacity: 0;
  }

  .ant-table-row:hover .actions {
    opacity: 1;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #f0f3f5;
  }
`
const TableSpace = styled.div`
  .ant-space-item {
    margin-right: 5px !important;
  }
`

const Card = styled(Layout)`
  border-radius: 32px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-bottom: 30px;
`
const OrderCard = styled(Layout)`
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 30px 30px;
  border-radius: 32px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-bottom: 48px;
  .title {
    margin-bottom: 8px;
    font-size: 28px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: 1.4px;
  }
  .entry-title {
    margin-bottom: 18px;
  }
`

const RoundModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 32px;
    background: #f7f7f7;
  }
  .ant-modal-close {
    padding: 20px 25px 0px 0px;
  }
  .ant-modal-header {
    background: none;
    border: none;
    padding: 32px 40px 0;
  }
  .ant-modal-title {
    font-size: 24px;
    font-weight: bold;
    color: #4c4c4d;
  }
  .ant-modal-body {
    padding: 20px 40px 32px 40px;
  }
  .ant-modal-footer {
    padding: 8px 40px 32px 0;
  }
  .ant-modal-footer button + button {
    margin-left: 16px;
  }
`

const RoundImage = styled(Image)`
  border-radius: 12px;
`

const VerticalDivider = styled(Divider)`
  background: #333333;
  height: 20px;
  margin: 10px 6px;
  opacity: 0.5;
`
const Dividers = styled(Divider)`
  margin: 12px 0;
  background-color: #bdbdbd;
  height: 1px;
`

export {
  RoundInput,
  PrefixInput,
  FilterInput,
  RoundInputNumber,
  RoundInputPassword,
  RoundTextArea,
  RoundButton,
  AddButton,
  OutlineButton,
  OrderButton,
  DisableButton,
  IconButton,
  RoundTag,
  RoundSelect,
  RoundMultipleSelect,
  RoundDatePicker,
  RoundRangePicker,
  RoundTable as Table,
  TableSpace,
  Card,
  OrderCard,
  RoundModal as Modal,
  RoundImage as Image,
  VerticalDivider,
  Dividers
}
