import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Layout, Row, Button, notification } from 'antd'
import Icon from '@ant-design/icons'
import Cookies from 'js-cookie'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { loginUpdated } from 'reduxSlice/loginSlice'
import { apiLogin } from 'API'
import { Form } from 'component/formStyled'
import { RoundInput, RoundInputPassword, MediaButton } from 'component/common'
import LoginFooter from 'component/LoginFooter'
import LoginLogo from './LoginLogo'
import { LoginSider, LoginTitle, LoginContent, ColForLogin } from './commonStyled'
import loginIllustration from 'images/login-illustration.png'
import { ReactComponent as icPassword } from 'images/ic_password.svg'
import { ReactComponent as icAccount } from 'images/ic_account.svg'

const EmailInput = styled(RoundInput)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px white inset !important;
  }
`
const Buttons = styled(Button)`
  width: 130px;
  min-height: 40px;
  font-weight: 600; 
  font-size: 14px;
  padding: 0px;
  @media (min-width: 1600px){
    font-size: 16px;
  }
  @media (min-width: 1920px){
    font-size: 18px; 
  }

`
const MediaButtons = styled(MediaButton)`
  width: 130px;
  margin-left: 16px;
`

export const RowFooter = styled(Row)`
  margin: 9% 0 10% 0;
`

const Login = () => {
  const formatMessage = useIntl().formatMessage
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()

  const [user, setUser] = useState({
    user_name: '',
    password: ''
  })

  const handleValuesChange = (changeItem) => {
    setUser({ ...user, ...changeItem })
  }

  const onFinish = () => {
    const data = {
      ...user
    }

    apiLogin(data).then(response => {
      const resData = response.data.data
      console.log('resData', resData)
      Cookies.set('metTk', response.headers.token)
      Cookies.set('userId', resData.id)
      dispatch(loginUpdated(true))
      history.push('/')
      notification.open({
        message: '登入成功',
        description: '您可以至設定選單中，查看帳號資訊與使用API金鑰及Callback功能。',
        duration: null
      })
    }).catch((error) => {
      if (error.response) {
        const errorMsg = error.response.data.error
        if (errorMsg.code === 20001) {
          notification.open({
            duration: null,
            message: formatMessage({ id: 'login.DeactivatedTitle' }),
            description: formatMessage({ id: 'login.DeactivatedDescription' })
          })
        } else if (errorMsg.code === 40001) {
          form.setFields([
            {
              name: 'user_name',
              errors: [formatMessage({ id: 'login.noAccount' })]
            }
          ])
        } else if (errorMsg.code === 40002) {
          form.setFields([
            {
              name: 'password',
              errors: [formatMessage({ id: 'login.incorrectPassword' })]
            }
          ])
        }
      }
    })
  }
  return (
    <Layout className='main-font'>
      <LoginSider>
        <Row type="flex" justify="center" align="middle" >
          <ColForLogin>
            <LoginLogo />
            <LoginTitle>
              <FormattedMessage id="login.signIn" />
            </LoginTitle>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onValuesChange={handleValuesChange}
              initialValues={{
                user_name: '',
                password: ''
              }}
            >
              <Form.Item
                label={formatMessage({ id: 'login.email' })}
                name="user_name"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'login.enterEmail' })
                  }
                ]}
                required={false}
              >
                <EmailInput
                  prefix={<Icon component={icAccount} />}
                  placeholder={formatMessage({ id: 'login.enterEmail' })}
                />
              </Form.Item>
              <Form.Item
                label={formatMessage({ id: 'login.password' })}
                name="password"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'login.enterPassword' })
                  }
                ]}
                required={false}
              >
                <RoundInputPassword
                  prefix={<Icon component={icPassword} />}
                  placeholder={formatMessage({ id: 'login.enterPassword' })}
                />
              </Form.Item>
              <RowFooter
                type="flex"
                justify="space-between"
              >
                <Link to="/forget-password">
                  <Buttons type="link">
                    <FormattedMessage id="login.forgotPassword" />
                  </Buttons>
                </Link>
                <Link to="/registration">
                  <Buttons type="link">
                    <FormattedMessage id="registration" />
                  </Buttons>
                </Link>
              </RowFooter>
              <Row justify="end">
                <MediaButtons
                  key="submit"
                  type="primary"
                  htmlType="submit"
                >
                  <FormattedMessage id="login.signIn" />
                </MediaButtons>
              </Row>
            </Form>
          </ColForLogin>
        </Row>
        <LoginFooter/>

      </LoginSider>
      <LoginContent>
        <img
          src={loginIllustration}
          className="illustration"
          alt="login-illustration"
        />
      </LoginContent>
    </Layout>
  )
}

export default Login
