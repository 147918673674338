import './App.css'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { notification, message, ConfigProvider, Layout, Grid } from 'antd'
import zhTW from 'antd/lib/locale/zh_TW'
import 'moment/locale/zh-tw'
import 'antd/dist/antd.min.css'
import moment from 'moment'
import Cookies from 'js-cookie'

import { dbRef } from './firebase'
// pages
import MaintainPage from './pages/maintainPage/MaintainPage'
import MobileMaintainPage from './pages/maintainPage/MobileMaintainPage'
import Login from './pages/loginPage/Login'
import MobileLogin from './pages/mobileLoginPage/MobileLogin'
import ForgetPassword from './pages/loginPage/ForgetPassword'
import MobileForgetPassword from './pages/mobileLoginPage/MobileForgetPassword'
import ResetPassword from './pages/loginPage/ResetPassword'
import MobileResetPassword from './pages/mobileLoginPage/MobileResetPassword'
import Registration from './pages/loginPage/Registration'
import MobileRegistration from './pages/mobileLoginPage/MobileRegistration'
import Home from './pages/Home'
import MobileHome from './pages/MobileHome'
import Overview from './pages/Overview'
import MobileOverview from './pages/MobileOverview'
import Scenario from './pages/Scenario'
import MobileScenario from './pages/MobileScenario'
// import Documents from './pages/Documents'
import DocumentsSwagger from './pages/DocumentsSwagger'
import Account from './pages/Account'
import MobileAccount from './pages/MobileAccount'

// others
import { apiGetUser } from 'API'
import { loginUpdated, userUpdated } from 'reduxSlice/loginSlice'
import { maintainUpdate, setDefault } from 'reduxSlice/maintainSlice'
import MaintainAlert from 'component/MaintainAlert'
import AppHeader from 'component/AppHeader'
import MobileHeader from 'component/MobileHeader'
import ScrollToTop from 'component/ScrollToTop'
import zh from './language/zh/allZh'
//
import 'css/notification.css'
import 'css/message.css'

const { Content } = Layout
const { useBreakpoint } = Grid

function App () {
  const { xs, sm, lg } = useBreakpoint()
  const dispatch = useDispatch()
  const token = Cookies.get('metTk')
  const userId = Cookies.get('userId')
  const isLogin = useSelector((state) => state.login.isLogin)
  const maintainStatus = useSelector((state) => state.maintain.status)
  const currentLocale = 'zh'
  const messages = zh
  const antLocale = zhTW

  notification.config({
    placement: 'bottomRight',
    duration: 3
  })

  message.config({
    top: 30,
    duration: 0
  })

  const [isDesk, setIsDesk] = useState(false)
  useEffect(() => {
    if (token && !isLogin) {
      dispatch(loginUpdated(true))
    }
  }, [dispatch, token, isLogin])

  useEffect(() => {
    if (lg) {
      setIsDesk(true)
    }
    if (xs || (sm && !lg)) {
      setIsDesk(false)
    }
  }, [xs, sm, lg])

  useEffect(() => {
    const listener = dbRef.orderByKey().limitToLast(1).on('value', (snapshot) => {
      if (snapshot.exists()) {
        const resData = snapshot.val()
        Object.keys(resData).forEach(key => {
          const maintainData = resData[key]
          if (maintainData) {
            const formatStyle = 'YYYY/MM/DD HH:mm'
            const nowTime = moment(new Date()).format(formatStyle)
            const dayAfter = moment(new Date()).add(7, 'days').format(formatStyle)
            const startTime = moment.unix(maintainData.start_time).format(formatStyle)
            const endTime = moment.unix(maintainData.end_time).format(formatStyle)
            const isMaintaining = moment(nowTime).isBetween(startTime, endTime)
            const isExpectMaintain = moment(startTime).isBetween(nowTime, dayAfter)

            // console.log('nowTime', nowTime, 'dayAfter:', dayAfter)
            // console.log('startTime', startTime, 'endTime:', endTime)
            // console.log('isMaintaining', isMaintaining, 'isExpectMaintain:', isExpectMaintain)

            const maintainTime = {
              start_time: maintainData.start_time,
              end_time: maintainData.end_time
            }

            if (isMaintaining) {
              const data = {
                maintainTime,
                status: 'maintaining'
              }
              if (isLogin) {
                Cookies.remove('metTk')
                Cookies.remove('userId')
                dispatch(loginUpdated(false))
              }
              dispatch(maintainUpdate(data))
            } else if (isExpectMaintain) {
              const data = {
                maintainTime,
                status: 'expect'
              }
              dispatch(maintainUpdate(data))
            } else {
              dispatch(setDefault())
            }
          }
        })
      }
    })

    return () => dbRef.off('value', listener)
  }, [dispatch, isLogin])

  useEffect(() => {
    const getUser = (userId) => {
      apiGetUser(userId)
        .then((response) => {
          dispatch(userUpdated(response.data.data[0]))
        })
        .catch((error) => {
          console.error('API Error:', error)
        })
    }
    if (isLogin) {
      getUser(userId)
    }
  }, [userId, isLogin, dispatch])

  return (
    <ConfigProvider locale={antLocale}>
      <IntlProvider locale={currentLocale} messages={messages}>
        <Router basename="/">
          { maintainStatus === 'expect' && <MaintainAlert />}
          { maintainStatus === 'maintaining' &&
            <Switch>
              <Route exact path="/">
                <Redirect to="/maintenance" />
              </Route>
              {isDesk
                ? <Route exact path="/maintenance" component={MaintainPage} />
                : <Route exact path="/maintenance" component={MobileMaintainPage} />}
              <Route path="*">
                <Redirect to="/maintenance" />
              </Route>
            </Switch>
          }
          { maintainStatus !== 'maintaining' &&
          <>
            <Layout style={{ background: 'transparent' }} >
              <ScrollToTop />
              {isDesk ? <AppHeader /> : <MobileHeader />}
              <Content>
                <Switch>
                  {isDesk
                    ? <Route exact path="/" component={Home} />
                    : <Route exact path="/" component={MobileHome} />}
                  <Route exact path="/documents" component={DocumentsSwagger} />
                  {isDesk
                    ? <Route exact path="/overview" component={Overview} />
                    : <Route exact path="/overview" component={MobileOverview} />}
                  {isDesk
                    ? <Route exact path="/account" component={Account} />
                    : <Route exact path="/account" component={MobileAccount} />}
                  {isDesk
                    ? <Route exact path="/scenario" component={Scenario} />
                    : <Route exact path="/scenario" component={MobileScenario} />}
                  {isDesk
                    ? <Route exact path="/login" component={Login} />
                    : <Route exact path="/login" component={MobileLogin} />}
                  {isDesk
                    ? <Route exact path="/forget-password" component={ForgetPassword} />
                    : <Route exact path="/forget-password" component={MobileForgetPassword} />}
                  {isDesk
                    ? <Route exact path="/reset-password" component={ResetPassword} />
                    : <Route exact path="/reset-password" component={MobileResetPassword} />}
                  {isDesk
                    ? <Route exact path="/registration" component={Registration} />
                    : <Route exact path="/registration" component={MobileRegistration} />}
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </Content>
            </Layout>
          </>
          }
        </Router>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default App
