const common = {
  'common.logout': '登出',
  'common.Cancel': '取消',
  'common.Confirm': '確認',
  'common.Submit': '新增',
  'common.Save': '儲存',
  // Processing loading
  'common.Processing': '正在處理中'
}
const form = {
  Email: '電子信箱',
  'email.enterEmail': '請輸入電子信箱地址',
  'email.validEmail': '無效的電子信箱',
  lastName: '姓氏',
  'lastName.enter': '請輸入姓氏',
  'lastName.valid': '無效的姓氏',
  firstName: '名字',
  'firstName.enter': '請輸入名字',
  'firstName.valid': '無效的名字',
  phoneNumber: '行動電話',
  'phoneNumber.enter': '請輸入行動電話',
  'phoneNumber.valid': '無效的行動電話',
  orderPhone: '訂購人聯絡電話',
  phone: '電話',
  'phone.enter': '請輸入電話',
  'phone.valid': '無效的電話',
  address: '地址',
  'address.enter': '請輸入地址'
}

const login = {
  'login.signIn': '登入',
  'login.email': '電子信箱',
  'login.enterEmail': '請輸入您的電子信箱',
  'login.password': '密碼',
  'login.enterPassword': '請輸入您的登入密碼',
  'login.forgotPassword': '忘記密碼',
  'login.registration': '註冊帳號',
  'login.noAccount': '此使用者帳號不存在',
  'login.incorrectPassword': '此使用者密碼不正確',
  'login.DeactivatedTitle': '帳號已停用',
  'login.DeactivatedDescription': '您的帳號已停用。如有任何問題請聯絡我們 (support@metropia.com)。'
}
const forgetPassword = {
  'forget.forgotPassword': '忘記密碼',
  'forget.resetInstructions': '請輸入您註冊用戶帳號時所使用的電子信箱，系統將會發送一封重設密碼的電子郵件給您。',
  'forget.sendInstructions': '已將重設密碼的連結寄送至您註冊的電子信箱，請點擊連結設定新的登入密碼。',
  'forget.email': '電子信箱',
  'forget.enterEmail': '請輸入電子信箱地址',
  'forget.validEmail': '無效的電子信箱',
  'forget.noAccount': '此使用者帳號不存在',
  'forget.resetAlertTitle': '重設密碼的電子郵件已發送！',
  'forget.resetAlertDescription': '請至您註冊的電子信箱開啟重設密碼連結，重新設定一組密碼。',
  'forget.send': '確認',
  'forget.resetSent': '已發送'
}
const resetPassword = {
  'reset.resetPassword': '重設密碼',
  'reset.newPassword': '新密碼',
  'reset.enternewPassword': '請輸入一組新密碼',
  'reset.confirmNewPassword': '重複新密碼',
  'reset.repeatNewPassword': '請再次輸入新密碼',
  'reset.PasswordNoMatch': '您輸入的新密碼不一致',
  'reset.submit': '確定',
  'reset.updatedAlertTitle': '新密碼已完成設定',
  'reset.updatedAlertDescription': '您的用戶帳號密碼已成功更新，請回到登入頁面重新登入。',
  'reset.ErrorTitle': '重設密碼失敗',
  'reset.commonErrorDescription': '非常抱歉，您所提交的重設密碼失敗，請稍後再重新設定密碼。',
  'reset.repeatErrorDescription': '您已使用此連結重設帳號密碼，請使用新密碼重新登入。',
  'reset.loginPage': '登入頁面',
  'reset.PattenError': '密碼需至少8碼，並包含1個大寫英文字母與1個數字。'
}

const registration = {
  registration: '註冊帳號',
  'registration.enterPassword': '請輸入密碼',
  'registration.confirmPassword': '確認密碼',
  'registration.repeatPassword': '請再次輸入密碼',
  'registration.company': '公司名稱',
  'registration.enterCompany': '請輸入公司名稱',
  'registration.confirm': '確認',
  'registration.repeatEmail': '此電子信箱已註冊',
  'registration.successTitle': '帳號已註冊',
  'registration.successDescription': '請至您註冊的電子信箱收取驗證信，完成信箱驗證。'
}

const authentication = {
  'authentication.Name': '姓名',
  'authentication.Email': '電子信箱'
}

const maintain = {
  'maintain.expectMaintain': '系統預計在 {startTime} 至 {endTime} 進行定期維護，期間內將無法使用票務、帳戶、行程等所有服務，敬請見諒！',
  'maintain.TemporarilyUnavailable': '系統維護中',
  'maintain.isMaintaining': '系統正在進行定期維護工程，',
  'maintain.isMaintainingFrom': '於 {startTime} 至 {endTime} 期間暫停服務。',
  'maintain.isMaintainingApologize': '造成諸多不便，敬請見諒！'
}

const openapi = {
  'api.Documents': 'API 文件',
  'api.Overview': '總覽',
  'api.start': '開始使用',
  'api.menuAccount': '帳號與API 金鑰',
  'api.Account': '帳號',
  'api.keyAndCallback': 'API 金鑰與 Callback',
  'api.key': 'API金鑰',
  'api.copy': '複製',
  'api.submit': '提交'
}

const zhTW = {
  ...common,
  ...form,
  ...login,
  ...forgetPassword,
  ...resetPassword,
  ...authentication,
  ...maintain,
  ...openapi,
  ...registration
}
export default zhTW
