import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Menu, Dropdown } from 'antd'
import styled from 'styled-components'
import Icon from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import Cookies from 'js-cookie'

import { apiLogot } from 'API'
import { loginUpdated } from 'reduxSlice/loginSlice'
import { IconButton } from 'component/common'
import { ReactComponent as Logomobile } from 'images/Logo_Umaji_s.svg'
import { ReactComponent as icSettings } from 'images/ic_settings.svg'
import { ReactComponent as IconLogout } from 'images/ic_logout.svg'
import { ReactComponent as IconPerson } from 'images/ic_person.svg'
import { ReactComponent as IconOverview } from 'images/icon_Overview.svg'
import { ReactComponent as IconAPI } from 'images/icon_API.svg'

const Navbars = styled.nav`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  height: 66px;
  padding: 12px 24px;
  background-color: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
`

const SettingContent = styled.div`
  margin-left: auto;
  svg {
    width: 28px;
    height: 28px;
  }
`
const SetupDropdown = styled(Dropdown)`
  font-size: 18px !important;
`
const SetupMenu = styled(Menu)`
  width: 200px;
  top: 17px;
  padding: 0;
  color: #333;
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 16px 12px;
    font-size: 18px !important;
  }
  .ant-dropdown-menu-item-icon {
    vertical-align: middle;
  }
  .ant-dropdown-menu-item > span,
  .ant-dropdown-menu-submenu-title > span {
    vertical-align: middle;
  }
  .ant-dropdown-menu-submenu-title > svg {
    vertical-align: middle;
  }
`

export default function MobileHeader () {
  const userId = Cookies.get('userId')
  const history = useHistory()
  const dispatch = useDispatch()
  const isLogin = useSelector((state) => state.login.isLogin)

  const logout = () => {
    apiLogot(userId).then(response => {
      Cookies.remove('metTk')
      Cookies.remove('userId')
      dispatch(loginUpdated(false))
      history.push('/')
    })
  }

  const menu = (
    <SetupMenu>
      <Menu.Item
        key="0"
        icon={<IconOverview />}
        onClick={() => history.push('/overview')}
      >
        <FormattedMessage id="api.Overview" />
      </Menu.Item>
      <Menu.Item
        key="1"
        icon={<IconAPI />}
        onClick={() => history.push('/documents')}
      >
        <FormattedMessage id="api.Documents" />
      </Menu.Item>
      {!isLogin &&
        <Menu.Item
          key="2"
          icon={<IconPerson />}
          onClick={() => history.push('/login')}
        >
          <FormattedMessage id="login.signIn" />
        </Menu.Item>
      }
      {isLogin &&
        <Menu.Item
          key="2"
          icon={<IconPerson />}
          onClick={() => history.push('/account')}
        >
          <FormattedMessage id="api.menuAccount" />
        </Menu.Item>
      }
      {isLogin &&
        <Menu.Item
          key="3"
          icon={<IconLogout />}
          onClick={logout}
        >
          <FormattedMessage id="common.logout" />
        </Menu.Item>
      }
    </SetupMenu>
  )

  return (
    <Navbars>
      <div>
        <Link to="/">
          <Logomobile alt="logo" />
        </Link>
      </div>
      <SettingContent>
        <SetupDropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
        >
          <IconButton
            type="text"
            onClick={(e) => e.preventDefault()}
            icon={<Icon component={icSettings} className="icon-setting" />}
          />
        </SetupDropdown>
      </SettingContent>
    </Navbars>
  )
}
