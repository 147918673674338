import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Content = styled.div`
  text-align: center;
  padding: 7% 16px 16px 16px;
  font-size: 14px;
  color: #8a8a8a;
  opacity: 0.5;
  @media (min-width: 1600px){
    font-size: 16px;
  }
`

export default function Copyright () {
  const [year, setYear] = useState('')
  useEffect(() => {
    const date = new Date().getFullYear()
    setYear(date)
  }, [])

  return (
    <Content> &copy; {year} Metropia Inc.</Content>
  )
}
