import React from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, notification, message } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { apiRegistration } from 'API'
import LoginFooter from 'component/LoginFooter'
import { FormLastName, FormFirstName } from 'component/form/FormName'
import { FormEmail } from 'component/form/FormEmail'
import FormInput from 'component/form/FormInput'
import { Form } from 'component/formStyled'
import { MediaButton } from 'component/common'
import { Container, Contents, LoginTitle, MediaButtons } from './commonStyled'

export const ColForRegistration = styled(Col)`
  width: 100%;
  padding: 0 8.5%; 
  @media (min-width: 1280px){
    padding: 7% 14%; 
  }
  @media (min-width: 1400px){
    padding: 7% 18%; 
  }
  @media (min-width: 1600px){
    padding: 8.5% 17.5%; 
  }
`

export default function MobileRegistration () {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const history = useHistory()

  const notificationkey = 'registration'
  const handleToLogin = () => {
    notification.close(notificationkey)
    setTimeout(() => {
      history.push('/login')
    }, 800)
  }
  const NotificationButton = (
    <>
      <MediaButton
        type="primary"
        onClick={handleToLogin}
        style={{ width: 140 }}
      >
        {formatMessage({ id: 'reset.loginPage' })}
      </MediaButton>
    </>
  )

  const onFinish = (value) => {
    message.loading(formatMessage({ id: 'common.Processing' }))
    const item = {
      ...value,
      language: 'zh',
      redirect_url: `${window.location.origin}/reset-password`
    }
    apiRegistration(item).then(response => {
      message.destroy()
      notification.open({
        message: formatMessage({ id: 'registration.successTitle' }),
        description: formatMessage({ id: 'registration.successDescription' }),
        duration: null,
        key: notificationkey,
        btn: NotificationButton
      })
    }).catch((error) => {
      if (error.response) {
        const errorMsg = error.response.data.error
        console.log('errorMsg', errorMsg)
        if (errorMsg?.code === 20003) {
          form.setFields([
            {
              name: 'email',
              errors: [formatMessage({ id: 'registration.repeatEmail' })]
            }
          ])
        } else {
          notification.open({
            message: '註冊帳號失敗',
            description: '非常抱歉，您所提交的帳號註冊失敗，請稍後再重新申請註冊。'
          })
        }
      }
      message.destroy()
    })
  }

  return (
    <Container className='main-font'>
      <Contents>
        <div>
          <LoginTitle>
            <FormattedMessage id="registration" />
          </LoginTitle>
          <Form
            layout="vertical"
            form={form}
            requiredMark={false}
            onFinish={onFinish}
            style={{ marginTop: 46 }}
          >
            <FormLastName />
            <FormFirstName />
            <FormInput
              name="company"
              label="registration.company"
              placeholder="registration.company"
              requiredText="registration.enterCompany"
            />
            <FormEmail />
            <Row
              type="flex"
              justify="center"
              style={{ margin: '32px 0 32px 0' }}
            >
              <MediaButtons
                key="submit"
                type="primary"
                htmlType="submit"
              >
                <FormattedMessage id="registration.confirm" />
              </MediaButtons>
            </Row>
          </Form>
        </div>
        <LoginFooter/>
        </Contents>
    </Container>
  )
}
