import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { Row, Col, message, Form, notification } from 'antd'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'

import { apiUpdateCallback } from 'API'
import { callBackUpdated } from 'reduxSlice/loginSlice'
import StringUtils from 'utils/StringUtils'
import { RoundButton, RoundInput } from 'component/common'
import { ReactComponent as IconCopy } from 'images/icon_copy.svg'
import { ReactComponent as IconSubmit } from 'images/icon_submit.svg'

const Container = styled.div`
  background: #fff;
  padding: 24px;
`

const RowContain = styled(Row)`
  font-size: 16px; 
  @media (min-width: 1920px){
    font-size: 18px; 
  }
`
const H2 = styled.h2`
  font-size: 18px !important;
  font-weight: 900 !important;
  letter-spacing: 0.45px;
  margin-bottom: 12px !important;
`

const Title = styled.div`
  display: inline-block;
  color: #8a8a8a;
`
const Value = styled.div`
  display: inline-block;
  margin-left: 12px;
  color: #333333;
`
const ApiKeyContent = styled.span`
  color: #333333;
  word-break: break-all;
`

const ButtonContent = styled.div`
  text-align: end;
  margin-top: 20px;
  .middle {
    vertical-align: middle;
    margin-right: 2px;
  }
`
const RoundButtons = styled(RoundButton)`
  font-weight: 900 !important;
  font-size: 18px;
  span {
    vertical-align: middle;
  }
`
const Forms = styled(Form)`
  width: 100%;
  .ant-form-item-label {
    padding: 0 0 16px;
  }
  .ant-form-item-label > label {
    font-size: 16px;
    color: #8a8a8a;
  }
  .ant-form-item-explain-error {
    color: #d9534f !important;
  }
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border-color: #d9534f !important;
  }
  @media (min-width: 1920px){
    .ant-form-item-label > label {
      font-size: 18px; 
    } 
  }
`

export default function MobileAccount () {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.login.user)
  const vendor = user?.vendor

  const taskUrl = vendor?.task_callback || ''
  const rideUrl = vendor?.ride_callback || ''

  useEffect(() => {
    form.setFieldsValue({
      task_callback: vendor?.task_callback,
      ride_callback: vendor?.ride_callback
    })
  }, [form, vendor])

  let name
  if (user?.first_name && user?.last_name) {
    name = StringUtils.getDisplayName(user)
  }

  const handleCopyInfo = () => {
    copy(vendor?.api_key)
    message.success({
      content: '已複製到剪貼簿',
      className: 'no-icon',
      duration: 3
    })
  }

  const handleCallback = (event, type) => {
    event.preventDefault()
    const value = form.getFieldValue(type)
    const callbackItem = {
      [type]: value
    }
    const newVendor = {
      ...user.vendor,
      ...callbackItem
    }

    apiUpdateCallback(vendor.id, callbackItem)
      .then((response) => {
        dispatch(callBackUpdated(newVendor))
        notification.open({
          message: 'Callback已提交',
          description: '您所填寫的Callback URL已完成提交。'
        })
      })
      .catch((error) => {
        console.error('API Error:', error)
        notification.open({
          message: '提交失敗',
          description: '非常抱歉，您所填寫的Callback URL提交失敗，請稍後再重新提交。'
        })
      })
  }

  return (
    <Container className='main-font'>
      <div>
        <H2><FormattedMessage id="api.Account" /></H2>
        <RowContain justify="space-between">
          <Col>
            <Title>{formatMessage({ id: 'authentication.Name' })}:</Title>
            <Value>{name}</Value>
          </Col>
          <Col>
            <Title>{formatMessage({ id: 'authentication.Email' })}:</Title>
            <Value>{user.email}</Value>
          </Col>
          <Col>
            <Title>{formatMessage({ id: 'registration.company' })}:</Title>
            <Value>{vendor?.name}</Value>
          </Col>
        </RowContain>
      </div>
      <div style={{ marginTop: 24 }}>
        <H2><FormattedMessage id="api.keyAndCallback" /></H2>
        <RowContain>
          <Col span={24}>
            <Row wrap={true}>
              <Col flex="none">
                <Title>{formatMessage({ id: 'api.key' })}:</Title>
              </Col>
              <Col flex="auto">
                <ApiKeyContent>{vendor?.api_key}</ApiKeyContent>
              </Col>
            </Row>
          </Col>
        </RowContain>
        <ButtonContent>
          <RoundButtons
            type="primary"
            icon={<IconCopy className='middle'/>}
            onClick={() => handleCopyInfo()}
          >
            <FormattedMessage id="api.copy" />
          </RoundButtons>
        </ButtonContent>
        <RowContain style={{ marginTop: 20 }}>
          <Forms
            layout="vertical"
            form={form}
            initialValues={{
              task_callback: taskUrl
            }}
          >
            <Form.Item
              label='任務回調 API:'
              name="task_callback"
              rules={[
                {
                  type: 'url',
                  message: '無效的URL'
                }
              ]}
            >
              <RoundInput autoComplete="off" name="myInput" placeholder='輸入任務 Callback' />
            </Form.Item>
            <ButtonContent>
              <RoundButtons
                type="primary"
                htmlType="submit"
                icon={<IconSubmit className='middle'/>}
                onClick={(e) => handleCallback(e, 'task_callback')}
              >
                <FormattedMessage id="api.submit" />
              </RoundButtons>
            </ButtonContent>
          </Forms>
        </RowContain>
        <RowContain style={{ marginTop: 20 }}>
          <Forms
            layout="vertical"
            form={form}
            initialValues={{
              ride_callback: rideUrl
            }}
          >
            <Form.Item
              label='行程回調 API:'
              name="ride_callback"
              rules={[
                {
                  type: 'url',
                  message: '無效的URL'
                }
              ]}
            >
              <RoundInput autoComplete="off" placeholder='輸入行程 Callback' />
            </Form.Item>
            <ButtonContent>
              <RoundButtons
                type="primary"
                htmlType="submit"
                icon={<IconSubmit className='middle'/>}
                onClick={(e) => handleCallback(e, 'ride_callback')}
              >
                <FormattedMessage id="api.submit" />
              </RoundButtons>
            </ButtonContent>
          </Forms>
        </RowContain>
      </div>
    </Container>
  )
}
