import firebase from 'firebase/app'
import 'firebase/database'
// import 'firebase/messaging'// fcm
// import 'firebase/auth'
import { firebaseConfig } from 'config/index.js'

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const firebaseRef = process.env.REACT_APP_ENV

export const dbRef = firebase.database().ref(`${firebaseRef}/application`).child('maintenance')
