import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as ImgLogo } from 'images/Logo_Umaji.svg'

export const LogoContent = styled.div`
  display: flex;
  justify-content: center;
  .logo {
    width: 150px;
    display: flex;
    margin: 25px 15px;
    overflow: visible !important;
    transition: all 0.3s;  
  }
  @media (min-width: 1600px){
    .logo {
      width: 200px;
      margin: 40px 20px;
    }
  }
`

export default function LoginLogo () {
  return (
    <>
      <Link to="/">
        <LogoContent>
          <ImgLogo className="logo" alt="logo" />
        </LogoContent>
      </Link>
    </>
  )
}
