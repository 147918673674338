import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Typography } from 'antd'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import maintenanceImg from 'images/maintenance.png'
import { ReactComponent as ImgLogo } from 'images/Logo_Umaji.svg'

const { Title } = Typography

export const ImageContent = styled.div`
  margin: 30px 0px;
  text-align: center;
  img {
    width: 450px;
    height: 300px;
  }
`
export const H1Title = styled(Title)`
  text-align: center;
`
export const Info = styled.p`
  text-align: center;
  font-size: 18px;
  line-height: 1.78;
`
export const LogoContent = styled.div`
  margin: 50px 10px 15px 10px;
  text-align: center;

  img {
    width: 200px;
  }
`

export default function MaintainPage () {
  const maintainTime = useSelector(state => state.maintain.data)

  moment.locale('en')
  const formatStyle = 'YYYY/MM/DD hh:mm A'
  const startTime = moment.unix(maintainTime.start_time).format(formatStyle)
  const endTime = moment.unix(maintainTime.end_time).format(formatStyle)

  return (
    <Row justify="center">
      <Col>
        <ImageContent>
          <img src={maintenanceImg} className="maintenance" alt="maintenance" />
        </ImageContent>
        <H1Title>
          <FormattedMessage id="maintain.TemporarilyUnavailable" />
        </H1Title>
        <Info>
          <FormattedMessage id="maintain.isMaintaining" /><br />
          <FormattedMessage
            id="maintain.isMaintainingFrom"
            values={{ startTime: startTime, endTime: endTime }}
          />
          <br />
          <FormattedMessage id="maintain.isMaintainingApologize" />
        </Info>
        <LogoContent>
          <ImgLogo alt="logo" />
        </LogoContent>
      </Col>
    </Row>
  )
}
