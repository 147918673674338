/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Typography } from 'antd'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { MediaButton } from 'component/common'
// import Copyright from 'component/Copyright'
import homeImg from 'images/home.png'
import appScreens from 'images/App_Screens.png'
import { ReactComponent as ImgLogo } from 'images/Logo_Umaji.svg'
import { ReactComponent as ImgLogo1 } from 'images/Logo1.svg'

const { Title, Paragraph } = Typography

const Container = styled.div`
  position: relative;
  background: #f7f7f7;
`
const Rows = styled(Row)`
  margin: 0 10%;
  padding-bottom: 14%;
`

const LogoContent = styled.div`
  margin: 5% 0 5% 0;
  img {
    width: 220px;
  }
  @media (min-width: 1400px){
    margin: 5% 0 6% 0;
  }
  @media (min-width: 1600px){
    margin: 5% 0 9% 0;
  }
`

const H1 = styled(Title)`
  letter-spacing: 1.5px;
  font-size: 36px !important;
  @media (min-width: 1600px){
    font-size: 40px !important;
    letter-spacing: 2px;
  }
  @media (min-width: 1920px){
    font-size: 48px !important;
    letter-spacing: 3px;
  }
  color: #333;
  margin-bottom: 2.9% !important;
`

const H3 = styled(Title)`
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: #1596b2 !important;
  margin-top: 0;
  margin-bottom: 7%;
  @media (min-width: 1600px){
    font-size: 27px !important;
  }
  @media (min-width: 1920px){
    font-size: 32px !important;
  }
`

const Paragraphs = styled(Paragraph)`
  font-size: 16px;
  line-height: 1.5;
  color: #8a8a8a;
  margin-bottom: 8% !important;
  @media (min-width: 1600px){
    font-size: 18px !important;
  }
  @media (min-width: 1400px){
    line-height: 1.5;
    margin-bottom: 10% !important;
  }
  @media (min-width: 1600px){
    line-height: 1.67;
    margin-bottom: 12% !important;
  }
`

const ImageContent = styled.div`
  text-align: right;
  .home-img {
    width: 500px;
  }
  @media (min-width: 1400px){
    .home-img {
      width: 550px;
    }
  }
  @media (min-width: 1600px){
    .home-img {
      width: 600px;
    }
  }
  @media (min-width: 1900px){
    .home-img {
      width: 700px;
    }
  }
`
const MediaButtons = styled(MediaButton)`
  width: 200px;
  @media (min-width: 1600px){
    width: 220px;
  }
  @media (min-width: 1900px){
    width: 240px;
  }
`

const MiddleContent = styled.div`
  position: absolute;
  transform: translateY(-50%);
  text-align: center;
  height: 292px;
  margin: 0px 24%;
  padding: 2% 5%;
  border-radius: 16px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
`
const H2 = styled(Title)`
  @media (min-width: 1600px){
    font-size: 37px !important;
  }
  @media (min-width: 1920px){
    font-size: 42px !important;
  }
  letter-spacing: 2.1px;
  color: #1596b2 !important;
  margin-bottom: 24px;
`
const H4 = styled(Title)`
  margin-bottom: 24px;
  color: #8a8a8a !important;
`

const Footers = styled.div`
  background-color: #ebebeb;
`

const FooterContent = styled.div`
  margin: 0px 14% 0 16%;
`
const AppTitle = styled(Title)`
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: #333 !important;
  text-align: center;
  padding-top: 19%;
  @media (min-width: 1600px){
    font-size: 27px !important;
  }
  @media (min-width: 1920px){
    font-size: 32px !important;
  }
`

const AppImageContent = styled.div`
  text-align: center;
  .app-img {
    width: 100%;
  }
`

const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80%;
`
const LeftContent = styled(AppContent)`
  align-items: flex-end;
  padding-right: 15%;
`

const RightContent = styled(AppContent)`
  align-items: flex-start;
  padding-left: 15%;
`

const AppItem = styled.p`
  padding: 5% 16%;
  border-radius: 20px;
  background-color: #f7f7f7;
  letter-spacing: 1px;
  text-align: center;
  color: #8a8a8a;
  font-weight: bold;
  font-size: 16px;
  @media (min-width: 1600px){
    font-size: 18px !important;
  }
  @media (min-width: 1920px){
    font-size: 20px !important;
  }
`

export default function Home () {
  const isLogin = useSelector((state) => state.login.isLogin)

  return (
<>
  <Container className='main-font'>
    <Rows>
      <Col span={11}>
        <LogoContent>
          <ImgLogo alt="logo" />
        </LogoContent>
        <H1>預約共乘接送平台功能模組</H1>
        <H3 level={3}>開發更多移動新商模</H3>
        <Paragraphs style={{ textAlign: 'justify' }}>
          若您本身為計程車業者、出租車隊、運輸業者，且希望能夠有效管理駕駛或增強車隊派遣管理以提升現有的載運服務，包含路徑優化、行程及任務管理或推出預約共乘叫車服務，可以在我們的預約共乘車隊管理平台申請帳號，並利用開放API整合您的各種載運需求及數據整合。
        </Paragraphs>
        {!isLogin &&
          <Link to="/login">
            <MediaButtons type="primary" >
              <FormattedMessage id="api.start" />
            </MediaButtons>
          </Link>
        }
      </Col>
      <Col span={10} offset={2}>
        <ImageContent>
          <img src={homeImg} className="home-img" />
        </ImageContent>
      </Col>
    </Rows>
    <MiddleContent>
      <H2 level={2}>一個平台，商機無限</H2>
      <H4 level={4}>預約共乘接送平台功能模組擁有許多提高效率的功能</H4>
      <Paragraphs style={{ textAlign: 'justify' }}>
        更智慧化駕駛調度、降低人力派遣駕駛、優化路線和共乘媒合功能。乘客預約後可以進行預約行程查詢、修改或取消行程，提升乘客預訂體驗。車隊管理部分可進行提交司機班表、提交車輛資訊、班表查詢、班表異動系統通知及取消司機班表等，透過系統管理車隊大大降低人力。
      </Paragraphs>
    </MiddleContent>
  </Container>
  <Footers style={{ paddingBottom: 15 }}>
    <FooterContent>
      <AppTitle level={3}>主要功能</AppTitle>
      <Row justify="center">
        <Col span={7}>
          <LeftContent>
            <AppItem style={{ maxWidth: 240, marginTop: 35 }}>提高車隊利用率</AppItem>
            <AppItem style={{ maxWidth: 220 }}>車輛路線優化</AppItem>
            <AppItem style={{ maxWidth: 200 }}>共乘媒合</AppItem>
          </LeftContent>
        </Col>
        <Col span={10}>
          <Row justify="center">
            <Col>
              <AppImageContent>
                <img src={appScreens} className="app-img" />
              </AppImageContent>
            </Col>
          </Row>
        </Col>
        <Col span={7}>
          <RightContent>
            <AppItem style={{ maxWidth: 200, marginTop: 35 }}>行程指派</AppItem>
            <AppItem style={{ padding: '5% 8%' }} >預約行程查詢、修改及取消</AppItem>
            <AppItem style={{ maxWidth: 240 }}>多人共乘服務</AppItem>
          </RightContent>
        </Col>
        {/* <Col span={24} style={{ marginLeft: 35 }}>
          <Copyright/>
        </Col> */}
      </Row>
    </FooterContent>
  </Footers>
  <div style={{ backgroundColor: '#426b95', padding: '20px 0px', color: '#fff' }}>
    <FooterContent>
      <Row justify='center'>
        <Col span={12}>
          <Row>
            <Col style={{ marginRight: 12 }}><ImgLogo1 alt="logo" /></Col>
            <Col>
              <p style={{ marginBottom: 0 }}>中華民國交通部</p>
              <p style={{ marginBottom: 0 }}>地址：10052臺北市中正區仁愛路1段50號</p>
            </Col>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <p style={{ marginBottom: 0 }}>中華民國交通部 版權所有</p>
          <p style={{ marginBottom: 0 }}>Copyright © 2022 MOTC All Rights Reserved.</p>
        </Col>
      </Row>
    </FooterContent>
  </div>
</>
  )
}
