import React from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'

import OverviewDiagram from 'images/Overview_diagram.png'
import PriorityReservation from 'images/priority-reservation.png'
import SamedayReservation from 'images/sameday-reservation.png'

const { Title, Paragraph } = Typography

const Contents = styled.div`
  padding: 24px;
  background-color: #fff;
`
const Paragraphs = styled(Paragraph)`
  font-size: 16px;
  color: #8a8a8a;
  ul {
    list-style-type: disc;
  }
  p {
    margin: 0 0 30px 23px;
  }
`
const H2 = styled(Title)`
  font-size: 18px !important;
  font-weight: 900 !important;
  letter-spacing: 0.45px;
  margin-bottom: 20px !important;
  text-align: center;
`
const H3 = styled(Title)`
  font-size: 16px !important;
  margin-bottom: 12px !important;
`
const ReservationImage = styled.div`
  margin: -30px 0 16px 0;
  .reservation-img {
    max-width: 100%;
    height: auto;
  }
`
const ImageContent = styled.div`
  margin-top: 60px;
  .overview-img {
    max-width: 100%;
    height: auto;
  }
`

export default function MobileScenario () {
  return (
    <Contents className='main-font'>
      <H2 level={2}>範例使用情境</H2>
      <Paragraphs>
        車隊服務商使用預約共乘接送平台 API 提供短程接駁服務。
      </Paragraphs>
      <H3 level={3}>駕駛及車輛設置</H3>
      <Paragraphs>
        <ol>
          <li>註冊新駕駛</li>
          <p>使用註冊新駕駛 API，帶入欲註冊駕駛的姓名、電話、Email、照片 base64 編碼字串，建立執行接駁服務的駕駛檔案，並取得駕駛識別碼。</p>
          <li>註冊新車輛</li>
          <p>使用註冊新車輛 API，依欄位需求填寫款式編號、顏色、車牌號碼、座位數量、站位數量、出廠年份。建立新車輛的檔案，並取得車輛識別碼。</p>
          <li>綁定車輛至駕駛名下</li>
          <p>使用修改駕駛資訊 API，輸入欲綁定駕駛識別碼，取得駕駛資訊後，在車輛欄位填寫車輛識別碼，提交後完成車輛綁定至該駕駛。</p>
        </ol>
      </Paragraphs>
      <H3 level={3}>乘客設置</H3>
      <Paragraphs>
        <ol>
          <li>註冊新乘客</li>
          <p>使用註冊新乘客 API，填寫欲註冊乘客的姓名、電話、Email。註冊成功後取得此乘客識別碼。</p>
        </ol>
      </Paragraphs>
      <H3 level={3}>每日車隊管理暨指派作業</H3>
      <Paragraphs>
        <ol>
          <li>建立新值勤表</li>
          <p>使用建立新值勤表 API，使用駕駛識別碼指定一個駕駛，並帶入開始與結束值勤時間 ( Unix time)，以及開始工作的地點 (經緯度)，完成建立駕駛值勤表。</p>
          <li>預約新行程</li>
          <p>使用預約新行程 API，輸入乘客識別碼與乘車人數，以及起終點的地址、名稱與經緯度，並填寫Unix time格式的出發時間或抵達時間，完成建立乘客的預約行程。</p>
          <li>系統配對</li>
          <p>系統會依行程需求，以及駕駛的執勤時間，去做接駁服務的媒合調配，配對完成後會建立對應的駕駛任務，以及乘客的行程，並通知駕駛與乘客，行程的詳細內容可使用對應 API 進行查詢與刪除。</p>
        </ol>
      </Paragraphs>
      <H3 level={3}>預約模式</H3>
      <Paragraphs>
        <ol>
          <li>優先預約</li>
          <p>最晚於行程前一日的 20:00 截止優先預約，於 22:00 回傳行程與任務指派結果。</p>
          <ReservationImage>
            <img src={PriorityReservation} className="reservation-img"/>
          </ReservationImage>
          <li>當日預約</li>
          <p>最早於行程前一日的 20:00 後開始當日預約，系統會在同日 23:00 回傳行程與任務指派結果。在前一日 23:00 後的預約行程，系統會在10分鐘內完成配對，並回傳指派結果 。</p>
          <ReservationImage>
            <img src={SamedayReservation} className="reservation-img"/>
          </ReservationImage>
        </ol>
      </Paragraphs>
      <ImageContent>
        <img src={OverviewDiagram} className="overview-img" />
      </ImageContent>
    </Contents>
  )
}
