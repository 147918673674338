import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Typography } from 'antd'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { MediaButton } from 'component/common'
// import Copyright from 'component/Copyright'
import homeImg from 'images/home.png'
import appScreens from 'images/m_App_Screens.png'
import imgfeature from 'images/m_Feature.png'
import { ReactComponent as ImgLogo1 } from 'images/Logo1.svg'

const { Title, Paragraph } = Typography

const Container = styled.div`
  background: #f7f7f7;
  padding-top: 20px;
`
const Rows = styled(Row)`
  margin: 0 6.5%;
  padding-bottom: 14%;
`
const H1 = styled(Title)`
  letter-spacing: 2px;
  font-size: 40px !important;
  font-weight: 900 !important;
  color: #333;
  margin-top: 12px;
  margin-bottom: 2.9% !important;
`

const H3 = styled(Title)`
  font-size: 24px;
  font-weight: 900 !important;
  letter-spacing: 1.2px;
  color: #1596b2 !important;
  margin-top: 0;
  margin-bottom: 7%;
`

const Paragraphs = styled(Paragraph)`
  font-size: 16px;
  line-height: 1.88;
  color: #8a8a8a;
`

const ImageContent = styled.div`
  .home-img {
    max-width: 100%;
    height: auto;
  }
`
const MediaButtons = styled(MediaButton)`
  width: 240px;
  font-size: 20px !important;
  padding: 12px 0;
  height: auto;
`

const MiddleContent = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #fff;
`
const H2 = styled(Title)`
  font-size: 40px !important;
  font-weight: 900 !important;
  letter-spacing: 2px;
  color: #1596b2 !important;
  margin-bottom: 0px !important;
`
const H4 = styled(Title)`
  font-weight: 900 !important;
  margin-top: 12px !important;
  margin-bottom: 28px !important;
  color: #8a8a8a !important;
`

const Footers = styled.div`
  background-color: #ebebeb;
`

const FooterContent = styled.div`
  margin: 0px 5%;
`

const AppTitle = styled(Title)`
  font-size: 24px;
  font-weight: 900 !important;
  letter-spacing: 1.2px;
  color: #333 !important;
  text-align: center;
  padding-top: 40px;
`

const AppImageContent = styled.div`
  text-align: center;
  .app-img {
    width: 100%;
  }
`

export default function MobileHome () {
  const isLogin = useSelector((state) => state.login.isLogin)
  return (
<>
  <Container className='main-font'>
    <Rows>
      <Col span={24}>
        <ImageContent>
          <img src={homeImg} className="home-img" />
        </ImageContent>
      </Col>
      <Col span={24}>
        <H1>預約共乘接送平台功能模組</H1>
        <H3 level={3}>開發更多移動新商模</H3>
        <Paragraphs style={{ textAlign: 'justify' }}>
          若您本身為計程車業者、出租車隊、運輸業者，且希望能夠有效管理駕駛或增強車隊派遣管理以提升現有的載運服務，包含路徑優化、行程及任務管理或推出預約共乘叫車服務，可以在我們的預約共乘車隊管理平台申請帳號，並利用開放API整合您的各種載運需求及數據整合。
        </Paragraphs>
        {!isLogin &&
          <div style={{ textAlign: 'center', marginTop: 40 }}>
            <Link to="/login" >
              <MediaButtons type="primary" >
                <FormattedMessage id="api.start" />
              </MediaButtons>
            </Link>
          </div>
        }
      </Col>
    </Rows>
  </Container>
  <MiddleContent>
    <Row style={{ margin: '0 6.5%' }}>
      <H2 level={2}>一個平台，商機無限</H2>
      <H4 level={4}>預約共乘接送平台功能模組擁有許多提高效率的功能</H4>
      <Paragraphs style={{ textAlign: 'justify' }}>
        更智慧化駕駛調度、降低人力派遣駕駛、優化路線和共乘媒合功能。乘客預約後可以進行預約行程查詢、修改或取消行程，提升乘客預訂體驗。車隊管理部分可進行提交司機班表、提交車輛資訊、班表查詢、班表異動系統通知及取消司機班表等，透過系統管理車隊大大降低人力。
      </Paragraphs>
    </Row>
  </MiddleContent>
  <Footers style={{ paddingBottom: 15 }}>
    <FooterContent>
      <AppTitle level={3}>主要功能</AppTitle>
      <Row justify="center">
        <Col span={24}>
          <Row justify="center">
            <Col>
              <AppImageContent>
                <img src={appScreens} className="app-img" />
              </AppImageContent>
              <div style={{ margin: '12px 6% 20px 6%' }}>
                <AppImageContent>
                  <img src={imgfeature} className="app-img" />
                </AppImageContent>
              </div>
            </Col>
          </Row>
        </Col>
        {/* <Col span={24}>
          <Copyright/>
        </Col> */}
      </Row>
    </FooterContent>
  </Footers>
  <div style={{ backgroundColor: '#426b95', padding: '20px 0px', color: '#fff' }}>
    <FooterContent>
      <Row justify='center'>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Row>
            <Col style={{ marginRight: 12 }}><ImgLogo1 alt="logo" /></Col>
            <Col>
              <p style={{ marginBottom: 0 }}>中華民國交通部</p>
              <p style={{ marginBottom: 0 }}>地址：10052臺北市中正區仁愛路1段50號</p>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <p style={{ marginBottom: 0 }}>中華民國交通部 版權所有</p>
          <p style={{ marginBottom: 0 }}>Copyright © 2022 MOTC All Rights Reserved.</p>
        </Col>
      </Row>
    </FooterContent>
  </div>
</>
  )
}
