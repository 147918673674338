import styled from 'styled-components'
import { Form } from 'antd'

const FormStyled = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 900 !important;
    line-height: 1.39;
    color: #8a8a8a;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #8a8a8a; 
  }
  .ant-form-item {
    margin-bottom: 30px;
  }
  @media (min-width: 768px){
    .ant-form-item {
      margin-bottom: 20px;
    }
  }
  .ant-form-item-explain-error {
    color: #d9534f !important;
  }
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border-color: #d9534f !important;
  }
`
export { FormStyled as Form }

export const FormDisabledSelectItem = styled(Form.Item)`
  .disabled .ant-select-selector {
    background: #ebebeb !important;
    color: #8a8a8a !important;
  }
`

export const OrderForm = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.39;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #333333;
  }
  .ant-form-item-explain-error {
    color: #d9534f;
  }
  .ant-form-item-has-error .ant-input {
    border-color: #d9534f !important;
  }
`

export const RideDetailForm = styled(Form)`
  .ant-form-item-label > label {
    font-size: 18px;
    line-height: 1.39;
    color: #8a8a8a;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #8a8a8a;   
  }
  .ant-form-item-label {
    padding: 0 0 14px !important;
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-form-item-explain-error {
    color: #d9534f;
  }
  .ant-form-item-has-error .ant-input {
    border-color: #d9534f !important;
  }
`
