import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLogin: false,
  user: {}
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginUpdated (state, action) {
      state.isLogin = action.payload
    },
    userUpdated (state, action) {
      state.user = action.payload
    },
    callBackUpdated (state, action) {
      state.user.vendor = action.payload
    }
  }
})

export const { loginUpdated, userUpdated, callBackUpdated } = loginSlice.actions

export default loginSlice.reducer
